import React, { useState  } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const backendCall = () => {
    getAccessTokenSilently({audience: 'dev-api'})
    .then((token) => {
      const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      };
      fetch("http://localhost:8000/v2/users/me", options)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setUserId(result.id);
          setFirstName(result.first_name);
          setLastName(result.last_name);
        }
      )
      .catch((error) => {
        setError(error.message);
      })
    })
    .catch((error) => {
      console.error(error);
      loginWithRedirect();
    }
    )
  }
  if (error) {
    return <div>ERROR: {error}</div>;
  }

  const showInfo = () => {
    if (isLoaded) {
      return (
        <div>
          ID: {userId}
          <br />
          First Name: {firstName}
          <br />
          Last Name: {lastName}
        </div>
      )
    }
  }

  const auth0Info = () => {
    if (!user) return;
    return (
      <div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
      </div>
    )
  }


  return (
    <div>
      {auth0Info()}
      <button onClick={() => backendCall()}> users/me backend call </button>
      <a href="http://localhost:4999/crm"> Go to V1 CRM </a>
      <br />
      <br />
      {showInfo()}
      <br />
      <br />
      <button onClick={() => logout({ returnTo: window.location.origin })} > Logout </button>
    </div>
  )
}

export default App;
